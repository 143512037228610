import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Sidebar = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const Content = styled.div`
  flex: 1 1 auto;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  color: #ececec;

  img {
    height: 30px;
  }
`;

export const Topbar = styled.div`
  display: flex;
  justify-content: space-between;

  background: #333;
  padding: 12px 20px;
`;

export const Hamburguer = styled.div`
  .menu {
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hamburguer {
    position: relative;
    display: block;
    background: #fff;
    width: 20px;
    height: 2px;
    transition: 0.5s ease-in-out;
  }

  .hamburguer:before,
  .hamburguer:after {
    background: #fff;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s ease-in-out;
  }

  .hamburguer:before {
    top: -5px;
  }

  .hamburguer:after {
    bottom: -5px;
  }

  input {
    display: none;
  }

  input:checked ~ label .hamburguer {
    transform: rotate(45deg);
  }

  input:checked ~ label .hamburguer:before {
    transform: rotate(90deg);
    top: 0;
  }

  input:checked ~ label .hamburguer:after {
    transform: rotate(90deg);
    bottom: 0;
  }

  ${media.greaterThan('medium')`
    display: none;
  `}
`;
