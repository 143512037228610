import React, { useState, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Route from '../../routes/Route';
import Dashboard from './Dashboard';
import Navbar from '../../components/Navbar';

import {
  Container,
  Content,
  Sidebar,
  Topbar,
  Logo,
  Hamburguer,
} from './styles';
import ConfigPage from './ConfigPage';

const Admin: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function resize() {
      const { innerWidth: width } = window;
      if (width < 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    }

    resize();

    window.addEventListener('resize', () => {
      resize();
    });
  }, []);

  return (
    <Container>
      <Topbar>
        <Logo>
          <img
            src="https://setrastreador.com.br/sistema/imagens/logoIcon.png"
            alt=""
          />
          CRM
        </Logo>
        <Hamburguer>
          <input
            id="menu-hamburguer"
            type="checkbox"
            onClick={() => (!isOpen ? setIsOpen(true) : setIsOpen(false))}
          />

          <label htmlFor="menu-hamburguer">
            <div className="menu">
              <span className="hamburguer" />
            </div>
          </label>
        </Hamburguer>
      </Topbar>
      <Sidebar>
        <Navbar isOpen={isOpen} />
        <Content>
          <Switch>
            <Route
              path="/admin/dashboard"
              exact
              component={Dashboard}
              isPrivate
            />
            <Route
              path="/admin/config"
              exact
              component={ConfigPage}
              isPrivate
            />

            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
        </Content>
      </Sidebar>
    </Container>
  );
};

export default Admin;
