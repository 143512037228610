import React, { useState, useEffect } from 'react';
import { FiChevronDown, FiBarChart2, FiSettings } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

import { Container, Menu, BottomMenu, Dropdown, DropdownMenu } from './styles';

interface NavbarProps {
  isOpen: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isOpen }) => {
  return (
    <Container isOpen={isOpen}>
      <Menu>
        <ul>
          <li>
            <NavLink to="/admin/dashboard" activeClassName="is-active">
              <p>
                <FiBarChart2 size={26} />
                {isOpen && 'Dashboard'}
              </p>
            </NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <Dropdown>
              <p>
                <FiSettings size={26} />
                {isOpen && 'Configurações'}
                <FiChevronDown size={26} />
              </p>
            </Dropdown>
            <DropdownMenu>
              <ul>
                <li>
                  <NavLink to="/admin/config/createUser">
                    <p>Cadastro usuario</p>
                  </NavLink>
                </li>
              </ul>
            </DropdownMenu>
          </li>
        </ul>
      </Menu>

      <BottomMenu />
    </Container>
  );
};

export default Navbar;
