import styled, { css } from 'styled-components';

interface ContainerProps {
  isOpen?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #5a5a5a;
  background: #333;
  height: 100%;
  width: 240px;
  transition: 0.4s ease-in-out;
  ${props =>
    !props.isOpen &&
    css`
      opacity: 0;
      pointer-events: none;
      float: left;
      margin-left: -240px;
    `}
`;

export const Menu = styled.div`
  flex: 1 1 auto;

  ul {
    list-style: none;
    li {
      &:hover > a {
        background: hsla(0, 0%, 100%, 0.13);
        opacity: 1;
      }

      a {
        display: block;
        padding: 14px 22px;
        color: #fff;
        line-height: 30px;
        cursor: pointer;
        opacity: 0.8;
        text-decoration: none;

        &.is-active {
          background: hsla(0, 0%, 100%, 0.23);
          opacity: 1;
        }

        p {
          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
          svg + svg {
            flex: 1 1 auto;
          }
        }
      }
    }
  }
`;

export const BottomMenu = styled.div`
  border-top: 1px solid #5a5a5a;

  ul {
    list-style: none;

    li {
      &:hover > a {
        background: hsla(0, 0%, 100%, 0.13);
        opacity: 1;
      }

      a {
        display: block;
        padding: 14px 22px;
        color: #fff;
        line-height: 30px;
        cursor: pointer;
        opacity: 0.8;
        text-decoration: none;

        &.is-active {
          background: hsla(0, 0%, 100%, 0.23);
          opacity: 1;
        }

        p {
          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
`;

export const Dropdown = styled.a``;

export const DropdownMenu = styled.div`
  display: none;
`;
