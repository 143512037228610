import React, { createContext, useCallback, useContext, useState } from 'react';
import api from '../services/api';

interface AuthState {
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: object;
}

interface SignInCredetials {
  email: string;
  password: string;
}

interface SignInFinal {
  email: string;
  senha: string;
}

interface AuthContextData {
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: object;
  signIn(credentials: SignInCredetials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@SetConsultoria:user');
    if (user) {
      return { user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const dataLogin: SignInFinal = {
      email,
      senha: password,
    };

    const response = await api.post('/FuncionarioSV?logar=1', dataLogin);

    const loginData = response.data;

    localStorage.setItem('@SetConsultoria:user', JSON.stringify(loginData));

    setData({ user: loginData });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@SetConsultoria:user');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
